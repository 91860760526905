import { css } from "@emotion/core"
import mq from "./media-queries"

const base = {
  fontFamily: "'Raleway', sans-serif",
  fontWeightExtraLight: 200,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontSizeDesktop: 3.8,
  fontSizeMobile: 2.4,
}

const headings = {
  fontFamily: "'Oswald', sans-serif",
}

const staticHeadings = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${headings.fontFamily};
    text-transform: uppercase;
  }

  h1 {
    font-size: 3.4em;
  }

  h2 {
    font-size: 2.7em;
  }

  h3 {
    font-size: 2.4em;
  }

  h4 {
    font-size: 2em;
  }

  h5 {
    font-size: 1.8em;
  }

  h6 {
    font-size: 1.6em;
  }

  ${mq[2]} {
    h1 {
      font-size: 4.8em;
    }

    h2 {
      font-size: 3.6em;
    }

    h3 {
      font-size: 2.9em;
    }

    h4 {
      font-size: 2.4em;
    }

    h5 {
      font-size: 2em;
    }

    h6 {
      font-size: 1.8em;
    }
  }
`

const baseFontStyling = css`
  html {
    font-family: ${base.fontFamily};
    font-size: 62.5%;
    /* Equal to 10px -  If you haven't set the font size anywhere on the page, then it is the browser default, which is often 16px */
  }

  p {
    font-size: 1.6em;
  }

  ${staticHeadings};
`

export default { base, headings, baseFontStyling }
